<script>
  import { request } from "../lib/tools/request";
  import ReadOnlyTransfer from "../lib/components/ReadOnlyTransfer.svelte";
  import { onMount } from "svelte";
  import TransferTitle from "../lib/components/TransferTitle.svelte";
  import SelectTransferStorage from "../lib/components/SelectTransferStorage.svelte";
  import EditTransfer from "../lib/components/EditTransfer.svelte";
  import { sizeLabels } from "../lib/stores/sizeLabels.js";

  export let params;
  let transfer = {};
  let saving = false;
  let errors = {};
  let transferItems = [];
  let totalTransfers = 0;
  $: {
    let t = 0;
    sizeLabels.forEach((s) =>
      Object.values(transferItems).forEach((i) => (t += Number(i[s]) || 0)),
    );
    totalTransfers = t;
  }

  const fetchTransfer = async () => {
    const { ok, data } = await request({
      path: `storage-transfers/${params.id}`,
    });

    if (ok) {
      transfer = data;
    }
  };

  const save = async () => {
    errors = {};
    saving = true;
    const { ok, error } = await request({
      path: `storage-transfers/${transfer.id}`,
      json: {
        id: transfer.id,
        storageFrom: transfer.storageFrom,
        storageTo: transfer.storageTo,
        comment: transfer.comment,
      },
    });

    if (!ok) {
      errors = error;
    }

    saving = false;
  };

  const send = async () => {
    await save();
    saving = true;
    const { error } = await request({
      path: `storage-transfers/${transfer.id}/send`,
      method: "POST",
    });

    if (error) {
      errors = error;
    } else {
      await fetchTransfer();
    }

    saving = false;
  };

  const cancel = async () => {
    saving = true;
    const { error } = await request({
      path: `storage-transfers/${transfer.id}/cancel`,
      method: "POST",
    });

    if (error) {
      errors = error;
    } else {
      await fetchTransfer();
    }

    saving = false;
  };

  const receive = async () => {
    saving = true;
    const { error } = await request({
      path: `storage-transfers/${transfer.id}/receive`,
      method: "POST",
    });

    if (error) {
      errors = error;
    } else {
      await fetchTransfer();
    }

    saving = false;
  };

  onMount(fetchTransfer);
</script>

<div class="w-full bg-white">
  <div class="w-full flex flex-col gap-3 py-2">
    <TransferTitle {transfer} />
    <SelectTransferStorage
      bind:storageFrom={transfer.storageFrom}
      bind:storageTo={transfer.storageTo}
      status={transfer.status}
      transferId={transfer.id}
    >
      <svelte:fragment>
        {#if ["created", "canceled"].includes(transfer.status)}
          <button
            disabled={saving}
            class="bg-blue-400 hover:bg-blue-600 rounded-xl text-white px-4 py-1.5 disabled:bg-gray-600 whitespace-nowrap"
            on:click={save}
            >Сохранить (+{totalTransfers})
          </button>
          <button
            disabled={saving}
            class="disabled:bg-gray-600 disabled:text-white bg-white hover:bg-slate-100 border border-slate-200 rounded-xl text-black px-4 py-1.5"
            on:click={send}
            >Отправить
          </button>
        {:else if ["in_progress"].includes(transfer.status)}
          <button
            disabled={saving}
            class="bg-gray-400 hover:bg-gray-500 rounded-xl text-white px-4 py-1.5"
            on:click={cancel}
            >Отменить
          </button>
          <button
            disabled={saving}
            class="bg-blue-400 hover:bg-blue-600 rounded-xl text-white px-4 py-1.5"
            on:click={receive}
            >Завершить
          </button>
        {/if}

        <div class="flex gap-1 text-red-400 text-sm items-center">
          {#each errors?.nonFieldErrors || [] as error}
            <span>{error}</span>
          {/each}
        </div>
      </svelte:fragment>
    </SelectTransferStorage>
    {#if ["created", "canceled"].includes(transfer.status)}
      <EditTransfer
        bind:storageFrom={transfer.storageFrom}
        bind:errors
        bind:transferItems
        bind:transfer
      />
    {:else if ["in_progress", "done"].includes(transfer.status)}
      <ReadOnlyTransfer {transfer} />
    {/if}
  </div>
</div>
