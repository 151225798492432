import page from "page";

export function setSearchParam(params) {
  const searchParams = new URLSearchParams(location.search);

  for (const [key, value] of params) {
    searchParams.delete(key);
    if (value) {
      searchParams.set(key, value);
    }
  }

  let path = location.pathname;
  if ([...searchParams.values()].length) {
    path += "?" + searchParams;
  }
  page(path);
}

export function getSearchParam(key) {
  return new URLSearchParams(location.search).get(key) || "";
}
