<script>
  import page from "page";

  import { user } from "../stores/user";

  import NavbarButton from "./NavbarButton.svelte";
  import { userHasAccess } from "../tools/userHasAccess";
  import { request } from "../tools/request.js";
  import { onMount } from "svelte";

  let extraUrls = [];
  let showExtra = false;

  $: visibleExtraUrls = extraUrls.filter((u) =>
    u.users.includes($user?.username),
  );

  async function logout() {
    await request({
      path: "logout",
      method: "post",
    });
    user.set(null);
    page("/login");
  }

  onMount(async () => {
    const { ok, data } = await request({
      path: "app-settings/EXTRA_PANEL_URLS",
    });
    if (ok) {
      extraUrls = data.value;
    }
  });
</script>

{#if $user}
  <nav
    class="flex h-11 w-screen gap-2 px-5 py-1 overflow-x-scroll print:hidden whitespace-nowrap"
  >
    <NavbarButton href="/search">
      <span class="material-symbols-outlined text-black">barcode</span>
    </NavbarButton>
    {#if userHasAccess($user, ["Склад просмотр"])}
      <NavbarButton href="/storage">Склад</NavbarButton>
    {/if}
    {#if userHasAccess($user, ["Склад просмотр"])}
      <NavbarButton href="/transfers">Перемещения</NavbarButton>
    {/if}
    {#if userHasAccess($user, ["Склад просмотр"])}
      <NavbarButton href="/revisions">Инвентаризация</NavbarButton>
    {/if}
    {#if userHasAccess($user, ["Склад просмотр"])}
      <NavbarButton href="/transfer-history">История</NavbarButton>
    {/if}
    {#if userHasAccess($user, ["Ассортимент просмотр"])}
      <NavbarButton href="/stock">Ассортимент</NavbarButton>
    {/if}
    <div class="w-2"></div>
    {#if userHasAccess($user, ["Контакты просмотр"])}
      <NavbarButton href="/crm/contacts">Контакты</NavbarButton>
    {/if}
    {#if userHasAccess($user, ["Сделки просмотр"])}
      <NavbarButton href="/crm/sales">Сделки</NavbarButton>
    {/if}
    <div class="w-2"></div>
    {#if userHasAccess($user, ["Материалы просмотр"])}
      <NavbarButton href="/warehouse/items">Материалы</NavbarButton>
    {/if}
    {#if userHasAccess($user, ["ЛЗ-карты просмотр"])}
      <NavbarButton href="/warehouse/cards">
        ЛЗ&#x2060;-&#x2060;карты
      </NavbarButton>
    {/if}
    {#if visibleExtraUrls.length}
      <div on:click={() => (showExtra = !showExtra)} role="presentation">
        <NavbarButton>Экстра</NavbarButton>
      </div>
    {/if}
    {#if showExtra}
      {#each visibleExtraUrls as extraUrl}
        <div class="h-full flex items-center">
          <a
            href={extraUrl.url}
            rel="noreferrer"
            target="_blank"
            class="flex justify-center items-center"
          >
            <NavbarButton>
              {extraUrl.label}
            </NavbarButton>
          </a>
        </div>
      {/each}
    {/if}
    <div class="ml-auto h-full flex items-center">
      <span class="text-xs text-slate-400"
        >{import.meta.env.VITE_VERSION || "?"}</span
      >
      <a
        href="https://unleashed-maxilla-d17.notion.site/7d894fc8e6d7434896df48f3cbf5fbda"
        target="_blank"
        rel="noreferrer"
        class="w-full h-full flex justify-center items-center"
      >
        <NavbarButton>
          <span class="material-symbols-outlined text-base">info</span>
        </NavbarButton>
      </a>
      <div on:keydown on:click|stopPropagation={logout} role="presentation">
        <NavbarButton danger>
          <span class="text-sm"
            >{$user.username}&nbsp;<i class="text-sm material-symbols-outlined"
              >logout</i
            ></span
          >
        </NavbarButton>
      </div>
    </div>
  </nav>
{/if}
