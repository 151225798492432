<script>
  import { request } from "../tools/request";
  import { onMount, createEventDispatcher } from "svelte";

  export let itemId = null;
  const dispatch = createEventDispatcher();
  let loading = true;
  let items = {
    next: "items",
    previous: null,
    count: 0,
    results: [],
  };

  async function getItems(nextParams) {
    const { ok, data } = await request({
      path: "items-history",
      params: { id: itemId, ...nextParams },
    });
    if (ok) {
      if (nextParams) {
        data.results = [...items.results, ...data.results];
      }
      items = { ...data };
    }
    loading = false;
  }

  onMount(getItems);

  document.addEventListener("scroll", () => {
    if (loading || !items.next) {
      return;
    }
    if (
      document.body.scrollHeight * 0.9 <=
      window.scrollY + window.innerHeight
    ) {
      loading = true;
      getItems(Object.fromEntries(new URL(items.next).searchParams.entries()));
    }
  });
</script>

<div class="flex flex-col gap-3 px-5">
  <span class="text-xl font-bold">История</span>
  {#each items.results as history}
    <div class="flex flex-col gap-0.5 text-xs">
      <div class="text-slate-400">
        ({history.historyUser?.username || "---"})
        <span>{new Date(history.historyDate).toLocaleString()}</span>
      </div>
      <div
        tabindex="-1"
        class="cursor-pointer font-semibold"
        role="button"
        on:keydown
        on:click={() => {
          itemId ? null : dispatch("historySelected", { id: history.id });
        }}
      >
        {history.vendorCode}
        {history.title}
      </div>
      <div>
        {#if history.historyType === "+"}
          Создано
        {:else if history.historyType === "-"}
          Удалено
        {:else}
          {#each history.diff as diff}
            <span>{diff.field}:</span>
            <span class="line-through">{diff.old}</span>
            <span>→</span>
            <span>{diff.new}</span>
            <br />
          {/each}
        {/if}
      </div>
    </div>
  {/each}
</div>
