<script>
  import Num from "./Num.svelte";
  import Discount from "./crm/Discount.svelte";
  import SaleStatus from "./crm/SaleStatus.svelte";
  import {reformatSize} from "../stores/sizeLabels.js";

  export let date = null
  export let sales = []

  let dateStr = ''
  let salesByDate = []
  let salesSum = 0
  let salesCount = 0

  $: {
    const parts = date.split('.')
    if (parts.length === 3) {
      const d = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0]))
      dateStr = new Intl.DateTimeFormat("ru-ru", {month: "long", day: "numeric"}).format(d)
    }
  }
  $: salesByDate = sales.filter((s) => s.startDate === date)
  $: salesSum = salesByDate.reduce((sum, sale) => sum + (sale?.sum || 0), 0)
  $: salesCount = salesByDate.reduce((sum, sale) => sum + (sale?.saleItems.length || 0), 0)
</script>


<tr>
  <td colspan="8" class="text-lg pt-5 pl-5">
    <span class="font-bold">{dateStr}</span>
    <span class="text-slate-400"><Num value={salesCount}/>/<Num value={salesSum}/>&nbsp;₽</span>
  </td>
</tr>
{#each salesByDate as sale}
  <tr
    class:bg-red-500={sale.status==='fail'}
    class:bg-purple-500={sale.status==='ready'}
    class:bg-blue-500={sale.status==='sent'}
    class:bg-yellow-500={sale.status==='created'}
    class="sm:hidden border-b bg-opacity-10">
    <td class="w-full pb-2 pt-4">
      <div class="w-full flex flex-col gap-1 px-5">
        <a href="/crm/sales/{sale.id}" class="flex gap-1 justify-between">
          <div class="flex gap-1">
            <span class="text-slate-400">{sale.id}</span>
            <SaleStatus key={sale.status} value={sale.statusLabel}/>
          </div>
          <div>
            <span class="font-bold"><Num value={sale.sum}/>&nbsp;₽</span>
          </div>
        </a>
        <a href="/crm/sales/{sale.id}" class="font-bold flex flex-col gap-1">
          {#each sale.saleItems || [] as saleItem}
            <div>{saleItem.item.title} {reformatSize(saleItem.size)}</div>
          {/each}
          {#if !sale.saleItems.length}
            <span>Нет наименований</span>
          {/if}
        </a>
        <div class="font-bold">
          <a href="/crm/sales/{sale.id}">
          </a>
        </div>
        <div>
          <span>{sale.startDate}
            {#if sale.endDate}-{sale.endDate || ""}{/if}</span>
        </div>
        <div class="flex gap-1">
          <a href="/crm/contacts/{sale.contact?.id}"
             class="text-blue-400 cursor-pointer hover:text-blue-500">
            {sale.contact?.lastName ?? ""}&nbsp;{sale.contact?.firstName ?? ""}</a>
          <span><Discount contact={sale.contact}/></span>
        </div>
        <div class="w-full whitespace-break-spaces text-slate-400 text-xs">
          {#if sale.comment}
            <span>Комментарий:</span>
            <span>{sale.comment}</span>
            <div>
              {#if sale.salesmen.length}
                Продавец: {sale.salesmen.map(s => `${s.firstName} ${s.lastName}`).join(', ')}{/if}
            </div>
          {/if}
        </div>
        <div class="text-slate-400 text-xs">
          <span>Магазин:</span>
          <span>{sale.shop?.title ?? ""}</span>
        </div>
      </div>
    </td>
  </tr>
  <tr
    class:bg-red-500={sale.status==='fail'}
    class:bg-purple-500={sale.status==='ready'}
    class:bg-blue-500={sale.status==='sent'}
    class:bg-yellow-500={sale.status==='created'}
    class="border-b hidden bg-opacity-10 sm:table-row">
    <td class="py-4 px-5">
      <a href="/crm/sales/{sale.id}">
        <div class="text-xs text-slate-400">{sale.id}</div>
        <div class="font-bold flex flex-col gap-1">
          {#each sale.saleItems || [] as saleItem}
            <div>{saleItem.item.title} {reformatSize(saleItem.size)}</div>
          {/each}
          {#if !sale.saleItems.length}
            <span>Нет наименований</span>
          {/if}
        </div>
      </a>
    </td>
    <td class="text-center px-3">
      <div>{sale.startDate}</div>
      <div>{sale.endDate || ""}</div>
    </td>
    <td class="text-center px-3">
      <div class="flex gap-1">
        <a href="/crm/contacts/{sale.contact?.id}"
           class="text-blue-400 cursor-pointer hover:text-blue-500">
          {sale.contact?.lastName ?? ""}&nbsp;{sale.contact?.firstName ?? ""}</a>
        <span><Discount contact={sale.contact}/></span>
      </div>
    </td>
    <td class="text-center px-3">
      {sale.shop?.title ?? ""}
    </td>
    <td class="text-center px-3">
      <Num value={sale.sum}/>
    </td>
    <td class="text-center px-3">
      <SaleStatus key={sale.status} value={sale.statusLabel}/>
    </td>
    <td class="text-xs text-slate-400 whitespace-break-spaces px-3 pb-2 min-w-[300px]">
      <div class="flex flex-col gap-0">
        <div>
          {sale.comment}
        </div>
        <div>
          {#if sale.salesmen.length}Продавец: {sale.salesmen.map(s => `${s.firstName} ${s.lastName}`).join(', ')}{/if}
        </div>
      </div>
    </td>
    <td class="text-center text-xs text-slate-400 px-3">
      <div>
        {sale.sourceLabel}
      </div>
      <div>
        {sale.sourceExtra}
      </div>
    </td>
  </tr>
{/each}