<script>
  import page from "page"
  import url from "../stores/url"

  export let href = null
  export let danger = false
</script>

<div
  class:bg-white={!danger && $url.pathname.startsWith(href)}
  class:text-black={!danger && $url.pathname.startsWith(href)}
  class:hover:text-red-400={danger}
  class="flex h-full text-sm items-center justify-center rounded-md px-2 py-1 font-semibold leading-4 text-slate-400 hover:bg-white/50 cursor-pointer"
  role="presentation"
  on:click={() => href ? page(href) : null}
>
  <slot />
</div>
