<script>
  import Button from "../lib/components/Button.svelte";
  import StockSettingsModal from "../lib/components/StockSettingsModal.svelte";
  import { request } from "../lib/tools/request.js";
  import { onMount } from "svelte";
  import { storagePopupKey } from "../lib/stores/storagePopupKey.js";
  import { tableRowKey } from "../lib/stores/tableRowKey.js";
  import { storageSizeSelected } from "../lib/stores/storageSizeSelected.js";
  import StorageItemsTable from "../lib/components/StorageItemsTable.svelte";
  import StorageItemHistoryModal from "../lib/components/StorageItemHistoryModal.svelte";

  export let params;

  let settingsModalActive;
  let storages = [];
  let exportLoading = false;
  let selectedStorage;
  let timer = null;
  let filter = null;

  const selectStorage = () => {
    storagePopupKey.set("");
    tableRowKey.set("");
    storages.forEach((s) => {
      if (`${s.id}` === params.id) {
        selectedStorage = s;
      }
    });
    storages = [...storages];
  };

  const fetchStorages = async () => {
    const { ok, data } = await request({
      path: "storages",
    });

    if (ok) {
      storages = [
        {
          id: 0,
          title: "Общий склад",
        },
        ...data.results,
      ];
      if (storages) {
        selectStorage();
      }
    }
  };

  const search = (val) => {
    if (timer) {
      clearTimeout(timer);
    }

    if (!val) {
      return;
    }

    timer = setTimeout(async () => {
      const { ok, data } = await request({
        path: "stock-items",
        params: {
          search: val,
        },
      });
      if (ok) {
        const match = data.results[0];
        if (!match) {
          return;
        }

        storageSizeSelected.set({
          id: match.id,
          size: match.sizeByCode ?? "size_one",
        });
      }
    }, 500);
  };

  const download = async (storage) => {
    if (exportLoading) {
      return;
    }
    exportLoading = true;
    const { ok, data } = await request({
      path: "storage-items/create_export_url",
      method: "POST",
      params: {
        storage: storage?.id,
      },
    });
    if (ok) {
      window.open(data.url, "_blank");
    }
    setTimeout(() => {
      exportLoading = false;
    }, 1000);
  };

  onMount(fetchStorages);
</script>

<div class="bg-white flex flex-col justify-between gap-5 pt-4">
  <StockSettingsModal
    title="Новый склад"
    path="storages"
    bind:active={settingsModalActive}
  >
    <div
      slot="extra-item"
      class="border border-slate-200 border-b-0 px-5 flex justify-between items-center last:border-b"
    >
      <div class="py-2.5 w-full">Общий склад</div>
      <div class="flex">
        <div
          role="presentation"
          class="h-full p-2.5 cursor-pointer"
          on:keydown
          on:click={download}
        >
          <i
            class="material-symbols-outlined text-xl {exportLoading
              ? 'text-slate-400'
              : 'text-green-500'}">download</i
          >
        </div>
        <div class="h-full p-2.5">
          <i class="material-symbols-outlined text-xl text-slate-400">delete</i>
        </div>
      </div>
    </div>
    <div slot="extra-button" let:item>
      <div
        role="presentation"
        class="h-full p-2.5 cursor-pointer"
        on:keydown
        on:click={() => {
          download(item);
        }}
      >
        <i
          class="material-symbols-outlined text-xl {exportLoading
            ? 'text-slate-400'
            : 'text-green-500'}">download</i
        >
      </div>
    </div>
  </StockSettingsModal>
  <div class="whitespace-nowrap">
    <div class="flex flex-col gap-1">
      <div class="flex gap-2 px-2 w-full overflow-x-scroll">
        <div class="flex gap-2 h-9">
          {#each storages as storage, i}
            <div>
              <a
                target="_self"
                href="/storage/{storage?.id ?? '0'}"
                class="h-full flex gap-1 justify-center whitespace-nowrap items-center rounded-xl border border-slate-200 px-2.5 text-sm disabled:cursor-not-allowed {selectedStorage?.id ===
                storage.id
                  ? 'bg-blue-400 text-white border-0'
                  : ''}"
              >
                {#if i === 0}<span class="material-symbols-outlined"
                    >shelves</span
                  >{/if}{storage.title}
              </a>
            </div>
          {/each}
        </div>
        <div class="ml-auto flex gap-2">
          <div>
            <Button
              on:click={() => (settingsModalActive = !settingsModalActive)}
              ><i class="material-symbols-outlined text-xl">settings</i></Button
            >
          </div>
        </div>
      </div>
      <div class="flex gap-2 px-2 w-full overflow-x-scroll">
        <div class="relative">
          <div
            class="absolute top-0 left-0 h-full flex justify-center items-center px-2"
          >
            <span class="material-symbols-outlined text-slate-400">barcode</span
            >
          </div>
          <input
            on:input={(e) => search(e.currentTarget.value)}
            placeholder="Поиск по штрихкоду"
            class="bg-white w-full min-w-[150px] max-w-[300px] pl-9 text-left p-1.5 border-1 border-slate-200 rounded-xl"
          />
          <select
            bind:value={filter}
            class="bg-white px-2 py-1 border-1 border-slate-200 rounded-xl text-center"
          >
            <option value={null}>Показать всё</option>
            <option value="excludeZero">Только в наличии</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="whitespace-nowrap w-full pb-20 overflow-x-scroll">
    {#if selectedStorage}
      <StorageItemsTable {filter} storageId={selectedStorage?.id} />
    {:else}
      <div class="px-2.5 text-xl h-screen">Выберите склад</div>
    {/if}
  </div>
  <StorageItemHistoryModal storage={selectedStorage} />
</div>
