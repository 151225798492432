<script>
  import WarehouseItems from "../lib/components/WarehouseItems.svelte";
  import WarehouseItemsHistory from "../lib/components/WarehouseItemsHistory.svelte";
  import {categories} from "../lib/stores/categories";

  import WarehouseCategories from "../lib/components/WarehouseCategories.svelte";
  import WarehouseAddCategoryModal from "../lib/components/WarehouseAddCategoryModal.svelte";
  import WarehouseAddItemModal from "../lib/components/WarehouseAddItemModal.svelte";

  import Search from "../lib/components/Search.svelte";
  import Button from "../lib/components/Button.svelte";
  import {request} from "../lib/tools/request";
  import {onMount} from "svelte";

  let categoryModalActive = false;
  let category;
  let search;
  let itemModalActive = false;
  let activeItem;
  let items = {
    next: "items",
    previous: null,
    count: 0,
    results: [],
  };

  onMount(getItems);

  async function getItems(nextParams) {
    const {ok, data} = await request({
      path: "items",
      params: {...nextParams, category, search},
    });
    if (ok) {
      if (nextParams) {
        data.results = [...items.results, ...data.results];
      }
      items = {...data};
    }
  }

  async function getNextItems() {
    getItems(Object.fromEntries(new URL(items.next).searchParams.entries()));
  }

  async function historySelected(event) {
    const {ok, data} = await request({
      path: `items/${event.detail.id}`,
    });
    if (ok) {
      activeItem = data;
      itemModalActive = true;
    }
  }

  $: search, category, getItems();
</script>

<div class="print:hidden w-full flex h-full justify-between gap-5">
  <div class="h-fit basis-full">
    <div class="h-full w-full bg-white py-5">
      <WarehouseAddCategoryModal bind:active={categoryModalActive}/>
      <WarehouseAddItemModal
        on:itemUpdated={() => getItems()}
        bind:active={itemModalActive}
        bind:item={activeItem}
      />
      <div class="flex flex-col gap-5">
        <div class="flex gap-2.5 px-5">
          <div>
            <WarehouseCategories bind:selected={category}/>
          </div>
          <div>
            <Search bind:search/>
          </div>
          <div class="ml-auto">
            <Button
              on:click={() => window.print()}>
              <span class="material-symbols-outlined">print</span></Button>
          </div>
          <div>
            <Button
              on:click={() => {
                categoryModalActive = true;
              }}
            >Категории
            </Button>
          </div>
          <div>
            <Button
              accent
              on:click={() => {
                activeItem = {};
                itemModalActive = true;
              }}
            >Добавить
            </Button>
          </div>
        </div>
        <WarehouseItems bind:items bind:activeItem bind:itemModalActive/>
      </div>
    </div>
    {#if items.next}
      <div class="w-32 h-10 mt-5">
        <Button accent on:click={getNextItems}>Загрузить ещё</Button>
      </div>
    {/if}
  </div>
  <div class="shrink-0 grow-0 basis-80 overflow-hidden">
    <div class="h-full w-full bg-white py-5">
      <WarehouseItemsHistory
        on:historySelected={historySelected}
      />
    </div>
  </div>
</div>

<div class="hidden print:block w-full">
  <table class="border-collapse">
    <thead>
    <tr>
      <th colspan="6"
          class="border text-center">{$categories?.find((c) => c.id === category)?.title || "Все категории"}</th>
    </tr>
    <tr>
      <th class="border text-center px-1">#</th>
      <th class="border text-center px-1">Название</th>
      <th class="border text-center px-1">Артикул</th>
      <th class="border text-center px-1">Единица измерения</th>
      <th class="border text-center px-1">Количество по складу</th>
      <th class="border text-center px-1">Фактическое количество</th>
    </tr>
    </thead>
    <tbody>
    {#each items?.results as item, i}
      <tr>
        <td class="border text-center px-1">{i}</td>
        <td class="border px-1">{item.title}</td>
        <td class="border text-center px-1">{item.vendorCode}</td>
        <td class="border text-center px-1">{item.unitLabel}</td>
        <td class="border text-center px-1">{item.quantity}</td>
        <td class="border text-center px-1"></td>
      </tr>
    {/each}
    </tbody>
  </table>
</div>

