<script>
  import { onMount } from "svelte";
  import { request } from "../lib/tools/request.js";
  import EditRevisionForm from "../lib/components/EditRevisionForm.svelte";

  export let params;
  $: revisionId = params.id === "add" ? null : params.id;
  let storages = [];
  let categories = [];
  let selectedStorage = null;
  let selectedCategory = null;
  let revision = {};
  let loading = false;

  const fetchRevision = async () => {
    if (!revisionId) {
      return;
    }
    const { ok, data } = await request({
      path: `revisions/${revisionId}`,
    });

    if (ok) {
      revision = data;
    }
  };

  const fetchStorages = async () => {
    const { ok, data } = await request({
      path: "storages",
    });

    if (ok) {
      storages = data.results;
    }
  };

  const fetchCategories = async () => {
    const { ok, data } = await request({
      path: "stock-categories",
    });

    if (ok) {
      categories = data.results;
    }
  };

  const createRevision = async () => {
    loading = true;
    if (!selectedStorage) {
      return;
    }

    const { ok, data } = await request({
      path: `revisions`,
      json: {
        storage: selectedStorage,
        categoryId: selectedCategory,
      },
    });

    if (ok) {
      location.pathname = `/revisions/${data.id}`;
    } else {
      loading = false;
    }
  };

  onMount(async () => {
    await Promise.all([fetchRevision(), fetchStorages(), fetchCategories()]);
  });
</script>

{#if revisionId}
  {#if revision.id}
    <EditRevisionForm {revision} />
  {:else}
    <span class="material-symbols-outlined animate-spin">progress_activity</span
    >
  {/if}
{:else}
  <div class="w-full h-full flex flex-col gap-2 pt-5">
    <div class="flex gap-5 h-10 px-5">
      <select
        bind:value={selectedStorage}
        class="bg-white px-2 py-1 border-1 border-slate-200 rounded-xl text-center"
      >
        <option value={null}>Выберите склад</option>
        {#each storages as storage}
          <option value={storage.id}>{storage.title}</option>
        {/each}
      </select>
      <select
        bind:value={selectedCategory}
        class="bg-white px-2 py-1 border-1 border-slate-200 rounded-xl text-center"
      >
        <option value={null}>Выберите категорию</option>
        {#each categories as category}
          <option value={category.id}>{category.title}</option>
        {/each}
      </select>
      <div>
        <button
          on:click={createRevision}
          disabled={loading || !selectedStorage || !selectedCategory}
          class:bg-blue-400={!loading && selectedStorage}
          class="disabled:bg-slate-400 px-2 py-1 text-white border rounded-xl flex justify-center items-center h-full"
          >Создать
        </button>
      </div>
    </div>
  </div>
{/if}
