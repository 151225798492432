<script>
  import { onMount } from "svelte";
  import { request } from "../lib/tools/request.js";
  import TransferStatus from "../lib/components/TransferStatus.svelte";
  import { getTitle } from "../lib/tools/transfers.js";

  let loading = false;
  let transfers = [];
  let next = {};
  let storages = {};
  let search = "";
  let timer = 0;

  const fetchStorageTransfers = async () => {
    loading = true;
    const { ok, data } = await request({
      path: "storage-transfers",
      params: {
        ...next,
        searchItems: search,
      },
    });

    if (ok) {
      transfers = transfers.concat(data.results);
    }

    next = {};
    if (data?.next) {
      next = Object.fromEntries(
        new URLSearchParams(new URL(data.next).search).entries(),
      );
    }
    loading = false;
  };

  const fetchStorages = async () => {
    const { ok, data } = await request({
      path: "storages",
    });

    if (ok) {
      storages = {};
      data.results.forEach((s) => (storages[s.id] = s));
    }
  };

  onMount(async () => {
    await Promise.all([fetchStorageTransfers(), fetchStorages()]);
  });

  function getStorage(storageId) {
    return storages[storageId]?.title || "";
  }

  const doSearch = () => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(async () => {
      next = {};
      transfers = [];
      await fetchStorageTransfers();
    }, 300);
  };
</script>

<div class="w-full bg-white">
  <div class="w-full flex justify-between items-center p-5">
    <span class="text-xl font-bold">Перемещения</span>
    <div class="flex gap-2 items-center">
      <div class="text-blue-500 text-sm">
        <a href="/transfers/import">Импорт</a>
      </div>
      <div
        class="w-full max-w-[300px] flex items-center justify-center rounded-lg border-1 border-slate-200 px-2 py-1"
      >
        <i class="material-symbols-outlined text-xl">search</i>
        <input
          bind:value={search}
          placeholder="Поиск"
          class="border-0 min-w-[100px] w-full pl-2.5"
          on:input={doSearch}
        />
      </div>
      <a href="/transfers/add">
        <button
          class="py-2 px-4 bg-blue-400 hover:bg-blue-600 text-white rounded-xl"
          >Создать</button
        >
      </a>
    </div>
  </div>
  <div class="overflow-hidden overflow-x-scroll">
    <div class="md:w-full inline-flex flex-col">
      {#if loading}
        <div class="w-full flex justify-center text-2xl">
          <span class="material-symbols-outlined animate-spin"
            >progress_activity</span
          >
        </div>
      {:else}
        {#each transfers as transfer}
          <a
            href="/transfers/{transfer.id}"
            class="whitespace-nowrap pointer w-full flex justify-between px-5 py-2 border-t last:border-b"
          >
            <div class="flex gap-2">
              <span>{getTitle(transfer)}</span>
              <span class="font-bold"
                >{getStorage(transfer.storageFrom)}
                {#if transfer.storageFrom && transfer.storageTo}→{/if}
                {getStorage(transfer.storageTo)}</span
              >
              <span>#{transfer.id} от </span>
              <span>{new Date(transfer.createdAt).toLocaleDateString()}</span>
              <span>({transfer.quantity} штук)</span>
              <TransferStatus
                value={transfer.status}
                label={transfer.statusLabel}
              />
              <div class="h-full flex items-center text-sm text-slate-400">
                {transfer.comment}
              </div>
            </div>
            <div class="text-sm text-gray-400 pl-5">
              Создал {transfer.user.username}
            </div>
          </a>
        {/each}
      {/if}
      {#if next?.page}
        <div class="w-full px-5 py-2 pb-5">
          <div>
            <button
              on:click={fetchStorageTransfers}
              disabled={loading}
              class="disabled:bg-gray-400 disabled:text-white py-2 px-4 bg-blue-400 hover:bg-blue-600 text-white rounded-xl"
            >
              Загрузить ещё
            </button>
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>
