<script>
  import { onMount } from "svelte";
  import { request } from "../lib/tools/request.js";
  import { getTitle } from "../lib/tools/transfers.js";
  import StorageItemHistoryRow from "./StorageItemHistoryRow.svelte";
  import SearchInput from "../lib/components/SearchInput.svelte";

  let loading = true;

  let storages = [];
  let users = [];
  let selectedTransfers = [];
  let selectedItems = [];
  let history = [];
  let next = {};
  let timer = 0;
  let startDate = null;
  let endDate = null;

  const dateFormat = new Intl.DateTimeFormat("ru-ru", {
    month: "long",
    day: "numeric",
  });

  // hack to search on every change in this values
  $: if (
    [
      true,
      storages,
      users,
      selectedTransfers,
      selectedItems,
      startDate,
      endDate,
    ].some((param) => !!param)
  ) {
    fetchHistory();
  }

  const fetchUsers = async () => {
    const { ok, data } = await request({
      path: "storage-transfers-history-users",
    });

    if (ok) {
      users = data.results;
    }
  };

  const fetchStorages = async () => {
    const { ok, data } = await request({
      path: "storages",
    });

    if (ok) {
      storages = data.results;
    }
  };

  const fetchHistory = async () => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(async () => {
      loading = true;
      const { ok, data } = await request({
        path: "storage-transfers-history",
        params: {
          user: filterSelectedIds(users),
          storage: filterSelectedIds(storages),
          transfer: filterSelectedIds(selectedTransfers),
          items: filterSelectedIds(selectedItems),
          start_date: startDate ? formatDate(startDate) : null,
          end_date: endDate ? formatDate(endDate, true) : null,
          ...next,
        },
      });

      if (!ok) {
        history = [];
        return;
      }

      data.results.map((h) => {
        h.createdAt = new Date(h.createdAt);
      });

      if (next.page) {
        history = [...history, ...data.results];
      } else {
        history = data.results;
      }

      if (data.next) {
        next = Object.fromEntries(new URL(data.next).searchParams.entries());
      } else {
        next = {};
      }

      loading = false;
    }, 500);
  };

  const selectObject = (list, index) => {
    next = {};
    list[index] = {
      ...list[index],
      selected: !list[index].selected,
    };
    return list;
  };

  const filterSelectedIds = (list) => {
    const items = list.filter((item) => item.selected).map((item) => item.id);
    if (items.length) {
      return items;
    }
  };

  const selectTransfer = (transfer) => {
    console.log(transfer);
    next = {};
    if (selectedTransfers.find((t) => t.id === transfer.id) === undefined) {
      selectedTransfers = [...selectedTransfers, transfer];
    } else {
      selectedTransfers = selectedTransfers.filter((t) => t.id !== transfer.id);
    }
    selectedTransfers = selectedTransfers.map((t) => ({
      ...t,
      selected: true,
    }));
  };

  const selectItem = (item) => {
    next = {};
    if (selectedItems.find((t) => t.id === item.id) === undefined) {
      selectedItems = [...selectedItems, item];
    } else {
      selectedItems = selectedItems.filter((t) => t.id !== item.id);
    }
    selectedItems = selectedItems.map((t) => ({ ...t, selected: true }));
  };

  const compareDates = (a, b) => {
    return (
      a?.createdAt.toLocaleDateString() === b?.createdAt.toLocaleDateString()
    );
  };

  const findStorage = (storageId) => {
    return storages.find((s) => s.id === storageId);
  };

  const formatDate = (dateString, endOfDay) => {
    return new Date(
      dateString + "T" + (endOfDay ? "23:59:59" : "00:00:00"),
    ).toISOString();
  };

  onMount(async () => {
    await Promise.all([fetchUsers(), fetchStorages(), fetchHistory()]);
  });
</script>

<div class="w-full bg-white">
  <div class="w-full flex gap-5 items-center p-5">
    <span class="text-xl font-bold">История изменений на складе</span>
    {#if loading}<span class="material-symbols-outlined animate-spin"
        >progress_activity</span
      >{/if}
  </div>
  <div class="w-full flex items-center gap-1 py-2 px-5 flex-wrap">
    <span class="text-sm pr-2 font-bold">Склады</span>
    {#each storages as storage, index}
      <button
        on:click={() => (storages = selectObject(storages, index))}
        class:bg-blue-400={storage.selected}
        class:text-white={storage.selected}
        class="px-4 text-xs py-1 border-1 border-slate-200 rounded-xl text-center"
      >
        {storage.title}
      </button>
    {/each}
  </div>
  <div class="w-full flex items-center gap-1 py-2 px-5">
    <span class="text-sm pr-2 font-bold">Даты</span>
    <input
      bind:value={startDate}
      on:change={() => (next = {})}
      class="px-4 text-xs py-1 border-1 border-slate-200 rounded-lg"
      placeholder="От"
      type="date"
    />
    <div>—</div>
    <input
      bind:value={endDate}
      on:change={() => (next = {})}
      class="px-4 text-xs py-1 border-1 border-slate-200 rounded-lg"
      placeholder="До"
      type="date"
    />
  </div>
  <div class="w-full flex items-center gap-1 py-2 px-5">
    <span class="text-sm pr-2 font-bold">Наименования</span>
    <SearchInput path="stock-items" onSelect={selectItem}>
      <div class="w-full flex items-center" slot="item" let:item>
        <div class="h-10 w-10">
          <img class="h-full" src={item.picture} alt="" />
        </div>
        <span class="font-bold">{item.title}</span>
      </div>
    </SearchInput>
    <div>
      {#each selectedItems as item}
        <button
          on:click={() => selectItem(item)}
          class:bg-blue-400={true}
          class:text-white={true}
          class="px-4 text-xs py-1 border-1 border-slate-200 rounded-xl text-center"
        >
          {item.title}
        </button>
      {/each}
    </div>
  </div>
  {#if history.length}
    <div class="w-full flex flex-col gap-2 px-5 py-5">
      {#each history as historyItem, index}
        {#if index === 0 || !compareDates(historyItem, history[index - 1])}
          <div class="text-xl font-bold pt-2">
            {dateFormat.format(historyItem.createdAt)}
          </div>
        {/if}
        <StorageItemHistoryRow history={historyItem}>
          <span slot="storage" class="font-medium">
            {historyItem.storage.title}
          </span>
          <a href="/stock/{historyItem.item.id}" target="_blank" slot="item">
            <span class="font-thin">|</span>
            <span class="font-bold">{historyItem.item.title}</span>
            <span class="font-thin">|</span>
          </a>
        </StorageItemHistoryRow>
        <div class="w-full h-px bg-gray-300"></div>
      {/each}
    </div>
  {:else}
    <div class="w-full flex justify-center min-h-screen">
      <div class="text-2xl">Не найдено</div>
    </div>
  {/if}
  {#if loading}
    <div class="w-full flex justify-center items-start text-2xl min-h-screen">
      <span class="material-symbols-outlined animate-spin"
        >progress_activity</span
      >
    </div>
  {:else if next?.page}
    <div class="w-full gap-2 px-5 py-5">
      <button
        on:click={() => {
          fetchHistory();
        }}
        class="p-2 bg-blue-500 text-white rounded-xl"
      >
        Загрузить ещё
      </button>
    </div>
  {/if}
</div>
