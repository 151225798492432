export function userHasAccess(user, groups) {
  if (user.isSuperuser) {
    return true;
  }
  for (const group of groups) {
    if (user.groups.includes(group)) {
      return true;
    }
  }
  return false;
}
