<script>
  import Num from "../lib/components/Num.svelte";
  import { getSearchParam, setSearchParam } from "../lib/tools/searchParams.js";
  import { onMount } from "svelte";
  import { request } from "../lib/tools/request.js";
  import IMask from "imask";
  import SalesByDate from "../lib/components/SalesByDate.svelte";
  import { user } from "../lib/stores/user";
  import { userHasAccess } from "../lib/tools/userHasAccess";

  const today = new Date().toISOString().split("T")[0];

  let loading = true;
  let salesTotal = null;
  let sales = null;
  let shops = [];
  let salesmen = [];
  let dates = [];
  let shop = getSearchParam("shop");
  let salesman = getSearchParam("salesman");
  let status = getSearchParam("status");
  let search = getSearchParam("status");
  let startDate = getSearchParam("startDate");
  let startDateGte = getSearchParam("startDateGte");
  let endDateLte = getSearchParam("endDateLte");
  let exportLoading = false;
  let next = null;
  let timer;
  const statusChoices = [
    { label: "Новый", key: "created" },
    { label: "Готов к отправке", key: "ready" },
    { label: "Отправлен", key: "sent" },
    { label: "Успешный", key: "success" },
    { label: "Ремонт принят", key: "repair_accepted" },
    { label: "Ремонт на производстве", key: "repair_in_progress" },
    { label: "Ремонт завершён", key: "repair_done" },
    { label: "Отмена", key: "fail" },
  ];

  $: setTimer([
    shop,
    salesman,
    status,
    search,
    startDate,
    startDateGte,
    endDateLte,
  ]);

  const setTimer = (_) => {
    clearTimeout(timer);
    timer = setTimeout(
      async () => await Promise.all([fetchSales(), fetchTotal()]),
      300,
    );
  };

  const fetchShops = async () => {
    const { ok, data } = await request({
      path: "shops",
    });

    if (ok) {
      shops = [...data.results];
    }
  };

  const fetchSalesmen = async () => {
    const { ok, data } = await request({
      path: "salesmen",
    });

    if (ok) {
      salesmen = [...data.results];
    }
  };

  const fetchTotal = async () => {
    const { ok, data } = await request({
      path: "sales/month-total",
      params: {
        ...getSearchParams(),
      },
    });

    if (ok) {
      salesTotal = data;
      salesTotal.label = new Intl.DateTimeFormat("ru-ru", {
        month: "long",
      }).format(new Date(salesTotal.datetime));
      salesTotal.labelPrev = new Intl.DateTimeFormat("ru-ru", {
        month: "long",
      }).format(new Date(salesTotal.datetimePrev));
    }
  };

  const download = async () => {
    if (exportLoading) {
      return;
    }
    exportLoading = true;
    const { ok, data } = await request({
      path: "sales/create_export_url",
      method: "POST",
      params: {
        search,
        shop,
        salesman,
        status,
        start_date: startDate,
        start_date__gte: startDateGte,
        end_date__lte: endDateLte,
      },
    });
    if (ok) {
      window.open(data.url, "_blank");
    }
    setTimeout(() => {
      exportLoading = false;
    }, 1000);
  };

  const getSearchParams = () => {
    return {
      search,
      shop,
      salesman,
      status,
      start_date: startDate,
      start_date__gte: startDateGte,
      end_date__lte: endDateLte,
    };
  };

  const fetchSales = async (useNext) => {
    setSearchParam([
      ["shop", shop],
      ["salesman", salesman],
      ["status", status],
      ["search", search],
      ["startDate", startDate],
      ["startDateGte", startDateGte],
      ["endDateLte", endDateLte],
    ]);

    loading = true;

    const { ok, data } = await request({
      path: "sales",
      params: {
        ...getSearchParams(),
        page: useNext ? next : 1,
      },
    });

    if (ok) {
      sales = useNext ? [...sales, ...data.results] : data.results;
      next = null;
      if (data.next) {
        next = new URL(data.next).searchParams.get("page");
      }

      dates = [];
      sales.forEach((s) => {
        if (dates.at(-1) === s.startDate) {
          return;
        }
        dates.push(s.startDate);
      });
      dates = [...dates];
    }
    loading = false;
  };

  onMount(async () => {
    await Promise.all([fetchShops(), fetchSalesmen(), fetchTotal()]);

    document.querySelectorAll("[data-date]").forEach((e) => {
      IMask(e, { mask: Date });
    });

    shop = Number(getSearchParam("storage")) || null;
    search = getSearchParam("search");
    startDate = getSearchParam("startDate");
    startDateGte = getSearchParam("startDateGte");
    endDateLte = getSearchParam("endDateLte");
  });
</script>

<svelte:head>
  <title>LPID - Сделки</title>
</svelte:head>

<div class="w-full bg-white py-5 flex flex-col gap-2">
  <a
    href="/crm/sales/add"
    class="fixed right-5 bottom-5 bg-blue-400 shadow-blue-400 shadow text-white flex justify-center items-center p-3 rounded-full"
  >
    <span class="material-symbols-outlined">add</span>
  </a>
  <div
    class="flex gap-2 items-center justify-between text-xl px-5 whitespace-nowrap overflow-x-scroll"
  >
    <div>
      <span class="font-bold">Сделки</span>
      {#if salesTotal}
        <div
          class="h-full text-xs sm:text-base flex-col sm:flex-row flex gap-1"
        >
          <span class="text-slate-400 font-normal"
            ><span class="capitalize">{salesTotal.label}&nbsp;</span><Num
              value={salesTotal.count}
            />&nbsp;шт / <Num value={salesTotal.sum} />&nbsp;₽</span
          >
          <span class="text-slate-400 font-normal"
            ><span class="capitalize">{salesTotal.labelPrev}&nbsp;</span><Num
              value={salesTotal.countPrev}
            />&nbsp;шт / <Num value={salesTotal.sumPrev} />&nbsp;₽</span
          >
        </div>
      {/if}
    </div>
    {#if userHasAccess($user, ["Отчёты Сделки"])}
      <div class="justify-self-start">
        <a href="/crm/sale-reports">
          <button
            class="text-base bg-white px-2 py-1 border-1 border-slate-200 rounded-xl text-center"
          >
            <span class="material-symbols-outlined">leaderboard</span>
          </button>
        </a>
      </div>
    {/if}
  </div>
  <div class="flex gap-2 items-center whitespace-nowrap overflow-x-scroll px-5">
    <select
      autocomplete="off"
      bind:value={shop}
      class="bg-white px-2 py-1 border-1 border-slate-200 rounded-xl text-center"
    >
      <option value={null}>Все магазины</option>
      {#each shops as shop}
        <option value={shop.id}>{shop.title}</option>
      {/each}
    </select>
    <select
      autocomplete="off"
      bind:value={salesman}
      class="bg-white px-2 py-1 border-1 border-slate-200 rounded-xl text-left"
    >
      <option value={""}>Все продавцы</option>
      <option value={-1}>Без продавцов</option>
      {#each salesmen as salesman}
        <option value={salesman.id}
          >{salesman.firstName ?? ""} {salesman.lastName ?? ""}</option
        >
      {/each}
    </select>
    <select
      autocomplete="off"
      bind:value={status}
      class="bg-white px-2 py-1 border-1 border-slate-200 rounded-xl text-left"
    >
      <option value={""}>Все статусы</option>
      {#each statusChoices as status}
        <option value={status.key}>{status.label}</option>
      {/each}
    </select>
    <button
      on:click={() => {
        startDate = startDate === today ? null : today;
      }}
      class="{startDate === today
        ? 'bg-blue-400 text-white'
        : 'bg-white'} px-2 py-1 border-1 border-slate-200 rounded-xl text-center"
    >
      Сегодня
    </button>
    <input
      data-date
      value={startDateGte}
      on:input={({ target: { value } }) => (startDateGte = value)}
      placeholder="От"
      class="bg-white w-24 px-2 py-1 border-1 border-slate-200 rounded-xl text-center"
    />
    <span>—</span>
    <input
      data-date
      value={endDateLte}
      on:input={({ target: { value } }) => (endDateLte = value)}
      placeholder="До"
      class="bg-white w-24 px-2 py-1 border-1 border-slate-200 rounded-xl text-center"
    />
    <button
      on:click={download}
      disabled={exportLoading}
      class:bg-slate-100={exportLoading}
      class="px-2 py-1 border-1 border-slate-200 rounded-xl text-center"
    >
      Экспорт
    </button>
    <div class="w-full flex justify-end">
      <div class="relative">
        <div
          class="absolute top-0 left-0 h-full flex justify-center items-center px-2"
        >
          <i class="material-symbols-outlined text-2xl text-slate-400">search</i
          >
        </div>
        <input
          bind:value={search}
          placeholder="Поиск"
          class="bg-white w-full min-w-[150px] max-w-[300px] pl-9 text-left p-1.5 border-1 border-slate-200 rounded-xl"
        />
      </div>
    </div>
  </div>
  <div class="w-full sm:whitespace-nowrap sm:overflow-x-scroll">
    <table class="w-full text-sm">
      <thead>
        <tr class="text-slate-400 text-left hidden sm:table-row">
          <th class="pt-2 pl-5 pr-2">Номер и состав</th>
          <th class="px-3">Дата</th>
          <th class="px-3">Покупатель</th>
          <th class="px-3">Магазин</th>
          <th class="px-3">Сумма</th>
          <th class="px-3">Стадия</th>
          <th class="px-3">Информация</th>
          <th class="pl-2 pr-5">Источник</th>
        </tr>
      </thead>
      <tbody>
        {#if loading && !sales?.length}
          <tr>
            <td colspan="8" class="text-center py-5">
              <span class="material-symbols-outlined animate-spin text-2xl"
                >progress_activity</span
              >
            </td>
          </tr>
        {:else if !sales?.length}
          <tr>
            <td colspan="8" class="text-center py-5">
              <span class="text-2xl">Нет сделок</span>
            </td>
          </tr>
        {:else}
          {#each dates as date}
            <SalesByDate {date} {sales} />
          {/each}
        {/if}
      </tbody>
      <tr>
        <td class="px-3 py-2" colspan="8">
          {#if next}
            {#if loading}
              <tr>
                <td colspan="8" class="text-center py-5">
                  <span class="material-symbols-outlined animate-spin text-2xl"
                    >progress_activity</span
                  >
                </td>
              </tr>
            {:else}
              <button
                on:click={() => {
                  fetchSales(true);
                }}
                class="p-2 bg-blue-500 text-white rounded-xl"
                >Загрузить ещё
              </button>
            {/if}
          {/if}
        </td>
      </tr>
    </table>
  </div>
</div>
